import React from 'react';
import './Header.scss';
import header_logo from '../../assets/img/Service_Logo_Kolor.svg';
import LanguageSelect from '../../components/header-component/language-selector/LanguageSelect';
import Button from '../../components/button/Button';
import HeaderMob from '../../components/header-component/language-selector/header-mob/HeaderMob';
import { HashLink} from 'react-router-hash-link';
import { Link,useMatch } from 'react-router-dom';
import { t } from 'i18next';
import { whitelists } from '../../i18n';


function Header(props) {
    const match = useMatch('/:lang/*');
    const navData = [
        {
            id:'services',
            description:props.t("nav_services")
        },
        {
            id:'rezults',
            description:props.t("nav_advantages")
        },
       
        {
            id:'advantages',
            description:props.t("nav_why_are_we")
        },
        {
            id:'b2b',
            description:props.t("nav_b2b")
        },
        {
            id:'footer',
            description:props.t("nav_contact")
        },
        ];

    let lang = match && match.params ?
        (whitelists.includes(match.params.lang) ? match.params.lang : null) :
        null;

    const homeLink = lang ? `/${lang}` : '/'; 

    const navItem = navData.map((item) => (
        <div key={item.id} className="nav-item"><HashLink to={`${lang ? `/${lang}/#${item.id}`: `/#${item.id}`}`} smooth>{item.description}</HashLink></div>
    ))
  return (
    <>
    <div className="header-wrapper">
        <div className="header-content">
            <div className="header-logo">
                <Link to={homeLink}><img src={header_logo} alt="home_logo"/></Link>
            </div>
            <div className="nav-wrapper">
                {navItem}
            </div>
            <div className="language-block-and-button-wrapper">
                <div className="language-wrapper">
                    <LanguageSelect onLanguageChange={(lange)=>props.changeLanguage(lange)}/>
                </div>
                <div className="button-wrapper">
                    <Button text={t("header_button")} handlerSelectModal={props.handlerSelectModal}/>
                </div>
            </div>
        </div>
    </div>
    <HeaderMob t={props.t} handlerSelectModal={props.handlerSelectModal} onLanguageChange={(lange)=>props.changeLanguage(lange)}/>
    </>
  );
}

export default Header;
