import React from 'react';
import './Footer.scss';
import Form from '../../components/form/Form';
import footerLogo from '../../assets/img/Service_Logo_Kolor.svg';
import fbLink from '../../assets/img/FacebookLink.svg';
import instLink from '../../assets/img/InstagramLink.svg';
import tiktok from '../../assets/img/tiktok.svg';
import viberImage from '../../assets/img/viberImg.svg';
import phoneImg from '../../assets/img/phoneImg.svg';
import mail from '../../assets/img/mailImg.svg';
import { HashLink } from 'react-router-hash-link';
import ScrollToTop from '../../components/scroll-to-top/ScrollToTop';
import { Link, useMatch } from 'react-router-dom';
import { whitelists } from '../../i18n';

function Footer({ t, handlerSelectModal, onFormFail, onFormSuccess }) {
    const match = useMatch('/:lang/*');
    const navData = [
        {
            id: 'services',
            description: t("nav_services")
        },
        {
            id: 'rezults',
            description: t("nav_advantages")
        },

        {
            id: 'advantages',
            description: t("nav_why_are_we")
        },
        {
            id: 'footer',
            description: t("nav_contact")
        },
    ];

    const contactsData = [
        {
            tel: "+48 574 530 150 ", 
            viberImage: ""
        },

    ];
    {/* {
            tel:"+48 547 510 790",
            viberImage:viberImage
        },
        {
            tel:"+48 547 950 860",
            viberImage:viberImage
        },
        {
            tel:"+48 547 690 750",
            viberImage:viberImage
        }, */}

    const navItem = navData.map((item) => (
        <div key={item.id} className="nav-item"><HashLink to={`/#${item.id}`} smooth>{item.description}</HashLink></div>
    ))

    let lang = match && match.params ?
        (whitelists.includes(match.params.lang) ? match.params.lang : null) :
        null;

    const homeLink = lang ? `/${lang}` : '/';
    const privacyLink = lang ? `/${lang}/privacy` : '/privacy';

    const contactsDataItem = contactsData.map((item) => (
        <div key={item.tel} className='contacts-footer-item'>
            <div className="contacts-footer-item-image"><img src={phoneImg} alt="phone" /></div>
            <div className="phone"><a href={`tel:${item.tel}`}>{item.tel}</a></div>
            <div className="viber-image"><img src={item.viberImage} alt="" /></div>
        </div>
    ));

    const socialLinks = [
        {
            img: fbLink,
            link: "https://www.facebook.com/plspoland"
        },
        {
            img: instLink,
            link: "https://www.instagram.com/plspoland/"
        },
        {
            img: tiktok,
            link: "https://www.tiktok.com/@plspoland"
        },
    ];

    const socialLinkItem = socialLinks.map((item) => (
        <div key={item.link} className="footer-link">
            <Link to={item.link} target='blank'><img src={item.img} alt="" /></Link>
        </div>
    ))

    return (
        <div id='footer' className="footer-wrapper">
            {/* <div className="footer-left">
            <div className="left-content">
                <div className="footer-capture">
                    {t("footer_capture")}
                </div>
                <div className="footer-form">
                    <Form t={t} textButton={t("form_button")} buttonWidth={{width:"60%"}} handlerSelectModal={handlerSelectModal} onFormSuccessSubmit={onFormSuccess} onFormFailSubmit={onFormFail}/>
                </div>
            </div>
  </div>*/}
            <div className="footer-right">
                <div className="right-content">
                    <div className="footer-logo">
                        <Link to={homeLink}><img src={footerLogo} alt="" /></Link>
                    </div>
                    <div className="footer-nav-wrapper">
                        <div className="footer-nav">
                            <div className="footer-menu">
                                {navItem}
                            </div>
                            {/* <div className="footer-links">
                            {socialLinkItem}
</div>*/}
                        </div>
                        <div className="footer-contacts">
                            <div className="footer-contacts-capture">
                                {t("map_description")}
                            </div>
                            <div className="footer-contacts-content">
                                {contactsDataItem}
                            </div>
                            <div className="contacts-mail-block">
                                <div className='mail-img'><img src={mail} alt="mail" /></div>
                                <div className='mail-link'><a href="mailto:biuro@plspoland.pl">biuro@plspoland.pl</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        © 2023 | All rights reserved | <Link to={privacyLink}>{t("footer_privacy_link")}</Link>
                    </div>
                </div>
            </div>
            <ScrollToTop />
        </div>
    );
}

export default Footer;